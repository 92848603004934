import { Box, Button, Theme } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useIsMobile, useNavigation } from "@outschool/ui-utils";
import React, { lazy, useCallback } from "react";

import { ClassInquiryType, useChatContext } from "../chatProvider";
import { Message } from "../components/Message";
import { ClassInquiryStatus, Sender } from "../consts";

const ActivityListing = lazy(() =>
  import("@outschool/ui-components-website/src/Listings/MobileListing").then(
    module => ({
      default: module.MobileListing
    })
  )
);

type topRecommendedActivityListingType =
  | ClassInquiryType["topRecommendedActivityListing"]
  | undefined;

type ClassInquiryStatus =
  | NonNullable<ClassInquiryType["classInquiries"][0]>["status"]
  | undefined;

export const SendClassRecommendationMessage = ({
  recommendedClass,
  searchPath,
  classInquiryStatus
}: {
  recommendedClass: topRecommendedActivityListingType;
  searchPath: string | null;
  classInquiryStatus: ClassInquiryStatus;
}) => {
  const { t } = useTranslation("client\\components\\chat\\teacherPullChat");

  const textForEligibleInquiry = t(
    "Your request has been sent to top teachers and will be reviewed. You will receive a direct message as soon as the perfect teacher is found. (You may send up to 3 teacher requests a day)"
  );

  const textForEligibleButNotSharedInquiry = t(
    "We didn't share this request with teachers because 3 requests have already been shared today."
  );

  const textForRecommendedClass =
    "\n\n" +
    t(
      "In the meantime, here’s a class that could be perfect for your learner."
    );

  const messageText = `${
    classInquiryStatus === ClassInquiryStatus.Eligible
      ? textForEligibleInquiry
      : textForEligibleButNotSharedInquiry
  }${!!recommendedClass && textForRecommendedClass}`;

  return (
    <SendClassRecommendationMessageWithText
      recommendedClass={recommendedClass}
      searchPath={searchPath}
      messageText={messageText}
    />
  );
};

const SendClassRecommendationMessageWithText = ({
  recommendedClass,
  searchPath,
  messageText
}: {
  recommendedClass: topRecommendedActivityListingType;
  searchPath: string | null;
  messageText: string;
}) => {
  const { t } = useTranslation("client\\components\\chat\\teacherPullChat");

  const trackAdEvent = useTrackEvent();
  const navigate = useNavigation();
  const isMobile = useIsMobile();

  const { setIsModalOpen } = useChatContext();

  const onClickSeeOtherSuggestedClasses = useCallback(() => {
    trackAdEvent("teacher_pull_see_other_suggested_classes_clicked");

    if (!!setIsModalOpen) {
      setIsModalOpen(false);
    }
    if (searchPath) {
      navigate(searchPath);
    }
  }, [searchPath, navigate, trackAdEvent, setIsModalOpen]);

  return (
    <Message data-private text={messageText} sender={Sender.System}>
      <>
        {recommendedClass && (
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              width: "100%",
              overflow: "hidden",
              boxShadow:
                "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 16px 56px 0px rgba(0, 0, 0, 0.16)",
              flexShrink: 0
            }}
          >
            <ActivityListing
              activity={recommendedClass.activity}
              leader={recommendedClass.leader}
              nextSection={null}
              disabled={false}
              pageLocation="SEARCH_CLASS_INQUIRY"
              openInNewTab={true}
              trackingProperties={{ fromTeacherPullChat: true }}
              isMiniListingCard={isMobile}
              showCTAButton={true}
            />
          </Box>
        )}
        {searchPath && (
          <Button
            size="small"
            variant="outlined"
            sx={(theme: Theme) => ({
              maxHeight: "3.4rem",
              width: "50%",
              [theme.breakpoints.down("md")]: {
                width: "100%"
              }
            })}
            onClick={onClickSeeOtherSuggestedClasses}
          >
            {t("See other suggested classes")}
          </Button>
        )}
      </>
    </Message>
  );
};
