import {
  Role,
  SendFollowUpQuestionMutation,
  SendFollowUpQuestionMutationVariables
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useMutation } from "@outschool/ui-apollo";
import React, { useState } from "react";

import { sendFollowUpQuestionMutation } from "../../../queries/ClassInquiryQueries";
import { useChatContext } from "../chatProvider";
import { ChatInputField } from "../components/ChatInputField";

export const SendFollowUpInput = () => {
  const { t } = useTranslation("client\\components\\chat\\teacherPullChat");
  const trackEvent = useTrackEvent();
  const {
    classInquiryUpdated,
    getFollowUpConversation,
    updateMessages,
    classInquiry
  } = useChatContext();
  const [inputValue, setInputValue] = useState("");

  const [sendFollowUpQuestion] = useMutation<
    SendFollowUpQuestionMutation,
    SendFollowUpQuestionMutationVariables
  >(sendFollowUpQuestionMutation);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    trackEvent("ai_guide_followup_submit_button_touch");

    if (inputValue) {
      const followUpConversation = getFollowUpConversation();
      followUpConversation.push({
        role: Role.User,
        content: inputValue
      });

      updateMessages(inputValue);

      try {
        const { data: followUpQuestionData } = await sendFollowUpQuestion({
          variables: {
            conversation: followUpConversation,
            classInquiryUid: classInquiry?.classInquiries[0]?.uid || ""
          }
        });

        if (!!followUpQuestionData?.sendFollowUpQuestion) {
          classInquiryUpdated(
            followUpQuestionData.sendFollowUpQuestion
              .topRecommendedActivityListing,
            followUpQuestionData.sendFollowUpQuestion.searchPath || "",
            followUpQuestionData.sendFollowUpQuestion.followUpQuestion || ""
          );
        }

        trackEvent("ai_guide_followup_answer_submitted", {
          classInquiryId: classInquiry?.classInquiries[0]?.uid,
          conversation: followUpConversation
        });
      } catch (error) {
        OsPlatform.captureError(error);
      }
    }
  };

  const inputHelperText = t(
    "Provide additional information or ask follow-up questions."
  );

  return (
    <form onSubmit={handleSubmit}>
      <ChatInputField
        inputHelperText={inputHelperText}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />
    </form>
  );
};
