// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import {
  UpdateCurrentUserMutation as UpdateCurrentUserMutationType,
  UpdateCurrentUserMutationVariables
} from "@outschool/gql-frontend-generated";
import { Mutation, gql, useMutation } from "@outschool/ui-apollo";
import { FullCurrentUserFragment } from "@outschool/ui-auth";
import React from "react";

export const updateCurrentUserMutation = gql`
  mutation UpdateCurrentUser(
    $name: String
    $email: String
    $subscribe: Boolean
    $photo: String
    $phone: String
    $isLeader: Boolean
    $payout_email: String
    $video: ID
    $studentAges: [Int!]
    $ageEmailSubscription: Boolean
    $details: UserDetailsInput
    $locale: UserLocale
    $location: LocationInput
    $isRecordingAutopostEnabled: Boolean
    $consented_to_coppa_notice_at: DateTime
    $default_currency: String
    $learner_mode_switch_auth_requirement: LearnerModeSwitchAuthRequirement
    $do_not_sell_my_personal_info: Boolean
    $teacherAttributes: TeacherAttributesInput
    $consent_to_share_class_history: Boolean
    $consent_to_share_favourites: Boolean
    $privacy_state: String
  ) {
    updateCurrentUser(
      name: $name
      email: $email
      subscribe: $subscribe
      photo: $photo
      phone: $phone
      isLeader: $isLeader
      payout_email: $payout_email
      video: $video
      default_currency: $default_currency
      studentAges: $studentAges
      ageEmailSubscription: $ageEmailSubscription
      details: $details
      locale: $locale
      location: $location
      isRecordingAutopostEnabled: $isRecordingAutopostEnabled
      consented_to_coppa_notice_at: $consented_to_coppa_notice_at
      learner_mode_switch_auth_requirement: $learner_mode_switch_auth_requirement
      do_not_sell_my_personal_info: $do_not_sell_my_personal_info
      teacherAttributes: $teacherAttributes
      consent_to_share_class_history: $consent_to_share_class_history
      consent_to_share_favourites: $consent_to_share_favourites
      privacy_state: $privacy_state
    ) {
      ...FullCurrentUserFragment
    }
  }
  ${FullCurrentUserFragment}
`;

export const UpdateCurrentUserMutation = ({
  // @ts-ignore
  children: updateCurrentUser,
  ...props
}) => (
  <Mutation {...props} mutation={updateCurrentUserMutation}>
    {(mutate: $TSFixMe) =>
      updateCurrentUser((variables: $TSFixMe) => mutate({ variables }))
    }
  </Mutation>
);

export function useUpdateCurrentUserMutation() {
  return useMutation<
    UpdateCurrentUserMutationType,
    UpdateCurrentUserMutationVariables
  >(updateCurrentUserMutation);
}
export function updateCurrentUserMutationDecorator(Component: $TSFixMe) {
  return function WrappedComponent(props: $TSFixMe) {
    const [updateCurrentUser] = useUpdateCurrentUserMutation();
    return <Component updateCurrentUser={updateCurrentUser} {...props} />;
  };
}
